<template>
    <div class="bg-[#F0F5FF] border border-2 border-[#E0E9FF] p-6 rounded-2xl flex-col gap-6 transition-all duration-300 ease-in-out z-20 w-full max-w-lg">
        <h1 class="text-[#555BA2] font-semibold leading-none text-2xl text-center">Our next check-in</h1>
        <div class="flex flex-col gap-3 my-6">
            <ScheduleFollowUp
                :is-collapsed="true"
                :date="scheduleDate"
                :recurring="scheduleRecurring"
                :disabled="loading"
                :variant="ScheduleFollowUpVariant.FULL"
                :title="title"
                :description="description"
                @date-update="handleDateUpdate"
                @recurring-update="handleRecurringUpdate"
            />
        </div>
        <div class="w-full flex flex-col items-center gap-4">
            <button class="w-fit transition-colors ease-in duration-150 rounded-full px-12 py-3 font-semibold bg-[#555BA2] hover:bg-[#4B508F] text-white" @click="handleSchedule">
                Confirm follow up
            </button>
            <button type="button" :disabled="loading" :class="loading ? '!hidden md:!flex' : ''" class="button button-text" data-cy="skip-personalization" @click="goHome">
                Skip
            </button>
        </div>
    </div>
</template>

<script setup>
import { router } from "@inertiajs/vue3";
import ScheduleFollowUp, { ScheduleFollowUpVariant } from "~vue/ScheduleFollowUp.vue";
import { logError, logUserInteraction } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { inject, ref } from "vue";

const props = defineProps({
    title: String,
    description: String,
    redirectPath: String,
});

const { $sendEvent } = inject("globalProperties");

const scheduleDate = ref(initializeDate());
const scheduleRecurring = ref(true);
const loading = ref(false);

function initializeDate() {
    const date = new Date(Date.now());
    date.setDate(date.getDate() + 5); // default to 5 business days later

    const dayOfWeek = date.getDay();
    if (dayOfWeek === 0) {
        // Sunday
        date.setDate(date.getDate() + 1); // Move to Monday
    } else if (dayOfWeek === 6) {
        // Saturday
        date.setDate(date.getDate() + 2); // Move to Monday
    }

    return date;
}

function handleDateUpdate(newDate) {
    scheduleDate.value = newDate;
}

function handleRecurringUpdate(recurring) {
    scheduleRecurring.value = recurring;
}

async function handleSchedule() {
    try {
        loading.value = true;
        await $sendEvent("schedule_follow_up", {
            event_description: `${props.title}: ${props.description}`,
            event_at_confirmed: DateTime.fromJSDate(scheduleDate.value).toISO(),
            recurring: scheduleRecurring.value,
            type: "followup",
        });
        logUserInteraction("onboarding_followup_scheduled", {});
        goHome();
    } catch (e) {
        logError(e);
    } finally {
        loading.value = false;
    }
}

function goHome() {
    router.visit(props.redirectPath, {
        onSuccess: () => {
            logUserInteraction("onboarding_home_reached", {});
        },
    });
}
</script>
