<template>
    <div
        ref="memoryRef"
        :class="animation"
        class="saved-memory shadow-sm hidden md:flex fixed overflow-hidden z-40 p-4 bg-white flex-col gap-2 text-center tracking-tighter rounded-xl border-2 border-[#E5BCEF]"
    >
        <div class="font-semibold text-base text-[#555BA2]">Question #{{ questionNumber }}</div>
        <div class="text-[#262626] font-regular text-lg">Saved to my memory</div>
    </div>
</template>

<script>
const SLIDE_ANIMATION_MS = 1500;
const BOUNCING_ANIMATION_MS = 500;
const BOUNCING_ANIMATION_ITERATIONS = 2;

export const TOTAL_ANIMATION_MS = BOUNCING_ANIMATION_MS * BOUNCING_ANIMATION_ITERATIONS + SLIDE_ANIMATION_MS;
</script>

<script setup>
import { computed, onMounted, ref, useTemplateRef } from "vue";

const props = defineProps({
    startDelay: {
        type: Number,
        default: () => 0,
    },
    questionNumber: Number,
    startCoordinates: {
        type: Object,
        default: () => ({ x: 0, y: 0 }),
    },
    endCoordinates: {
        type: Object,
        default: () => ({ x: 0, y: 0 }),
    },
});

const animation = ref("");
const memoryRef = useTemplateRef("memoryRef");

onMounted(() => {
    /*
     * Orchestrate animation changes based on their
     * durations, as well as any start delay passed by the
     * parent component.
     */
    setTimeout(() => {
        animation.value = "bouncing";
        setTimeout(() => {
            animation.value = "slide-out";
        }, BOUNCING_ANIMATION_MS * BOUNCING_ANIMATION_ITERATIONS);
    }, props.startDelay);
});

/*
 * Size of the element at the end of the last animation
 */
const endScale = 0.1;
const rect = computed(() => memoryRef.value.getBoundingClientRect());

const startCoordinates = computed(() => {
    /*
     * Position and align the element in center of the passed start coordinates.
     */
    const x = props.startCoordinates.x - rect.value.width / 2;
    const y = props.startCoordinates.y - rect.value.height / 2;
    return {
        x,
        y,
    };
});

const startCoordinatesPx = computed(() => {
    return `${startCoordinates.value.x}px, ${startCoordinates.value.y}px`;
});

const endCoordinatesBouncePx = computed(() => {
    const x = startCoordinates.value.x;
    const y = startCoordinates.value.y - 16;
    return `${x}px, ${y}px`;
});

const endCoordinates = computed(() => {
    /*
     * Position and align the element in the center of the end coordinates.
     */
    const x = props.endCoordinates.x - rect.value.width / 2;
    const y = props.endCoordinates.y - rect.value.height / 2;
    return { x, y };
});

const endCoordinatesPx = computed(() => {
    return `${endCoordinates.value.x}px, ${endCoordinates.value.y}px`;
});
</script>

<style scoped>
@keyframes saved {
    0% {
        transform: translate(v-bind(startCoordinatesPx)) scale(1);
        opacity: 1;
    }

    80% {
        opacity: 0.6;
    }

    100% {
        opacity: 0;
        transform: translate(v-bind(endCoordinatesPx)) rotate(15deg) scale(v-bind(endScale));
    }
}

@keyframes bounce {
    0% {
        transform: translate(v-bind(startCoordinatesPx));
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    50% {
        transform: translate(v-bind(endCoordinatesBouncePx));
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    100% {
        transform: translate(v-bind(startCoordinatesPx));
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.saved-memory {
    transform-origin: center center;
    will-change: transform, opacity;
    transform: translate(v-bind(startCoordinatesPx));
}

.bouncing {
    animation: bounce v-bind(BOUNCING_ANIMATION_MS + "ms");
    animation-iteration-count: v-bind(BOUNCING_ANIMATION_ITERATIONS);
}

.slide-out {
    /*
     * Using ease-in-cubic from https://tools.webdevpuneet.com/css-easing-generator/
     */
    animation: saved v-bind(SLIDE_ANIMATION_MS + "ms") cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards;
}
</style>
