<template>
    <Transition name="fade" mode="out-in" appear>
        <!-- TODO(marcos): this OUTRO STEP and component is not being used anymore -->
        <Outro
            v-if="step === STEP.OUTRO"
            :redirect-text="post_onboarding_action_text"
            :redirect-path="post_onboarding_redirect"
            :first-name="user_details.first_name"
            :current-date="current_date"
            :logo-url="logoUrl"
        ></Outro>
        <div v-else class="relative px-6 py-6 md:p-4 flex flex-col items-center md:items-stretch md:grid md:grid-cols-12 gap-8 h-screen w-full overflow-y-auto bg-white">
            <template v-if="step === STEP.QUESTIONS">
                <template v-for="(question, idx) in questions" :key="idx">
                    <Transition name="fade">
                        <SavedMemory
                            v-if="question.showMemoryAnimation"
                            :start-delay="SAVED_MEMORY_ANIMATION_DELAY_MS"
                            :start-coordinates="questionsStackCenterCoordinates()"
                            :end-coordinates="marbleCenterCoordinates()"
                            :question-number="idx + 1"
                        />
                    </Transition>
                </template>
            </template>
            <Bubbles direction="bottom" class="md:hidden" />
            <!-- On mobile we show the logo on its own row, independent of the other content -->
            <div class="md:hidden block order-1">
                <img height="32" class="h-8 mb-2 w-auto mx-auto block" :src="logoUrl" />
            </div>
            <div :class="columnOrderInverted ? 'order-3' : 'order-2'" class="md:order-1 w-full z-30 md:col-span-7 flex flex-col items-center justify-center gap-5 md:gap-10">
                <!-- On desktop the logo is shown in the left-side column span -->
                <div class="hidden md:block">
                    <img height="48" class="h-9 md:h-12 w-auto mx-auto block" :src="logoUrl" />
                </div>
                <Personalization
                    v-if="step === STEP.PERSONALIZATION"
                    :errors="errors"
                    :default-language="default_language"
                    :language-options="language_options"
                    @submit="onPersonalizationSubmit"
                />
                <Questions
                    v-else-if="step === STEP.QUESTIONS"
                    ref="questionsComponentRef"
                    :is-within-answer-timeout="answerTimeout !== null"
                    :answer-timeout-ms="ANSWER_TIMEOUT_MS"
                    :answer-errored="activeListeningError !== null"
                    :disabled="isLoading"
                    :current-question="currentQuestion"
                    :current-question-position="currentQuestionIndex + 1"
                    :questions-count="questions.length"
                    @answer="handleQuestionAnswer"
                    @more-context="handleMoreContext"
                    @next="handleNextQuestion"
                    @recording-level="handleRecordingLevel"
                    @recording-start="handleRecordingStart"
                    @recording-stop="handleRecordingStop"
                    @transcribe-start="isTranscribing = true"
                    @transcribe-error="handleErrorFallback"
                    @transcribe-end="isTranscribing = false"
                    @view-answer="handleViewAnswer"
                >
                </Questions>
                <Insights v-else-if="step === STEP.INSIGHTS" :insights="insights" @done="handleDone" @checkin="handleCheckin" />
                <ScheduleCheckin
                    v-else-if="step === STEP.CHECKIN"
                    :redirect-path="post_onboarding_redirect"
                    :title="insight_checkin.title || 'Nadia Check-in'"
                    :description="insight_checkin.content || ''"
                />
                <div v-if="showPrivacyDialog || showSkip" class="w-full max-w-sm flex items-center justify-center">
                    <Transition name="fade">
                        <div v-if="showPrivacyDialog" :class="isRecordingProcessing || isLoading ? 'hidden md:block' : ''" class="flex-1">
                            <PrivacyDialog :disabled="isRecordingProcessing || isLoading" />
                        </div>
                    </Transition>
                    <Transition name="fade">
                        <button
                            v-if="showSkip"
                            type="button"
                            :disabled="isLoading || isRecordingProcessing"
                            :class="isLoading || isRecordingProcessing ? '!hidden md:!flex' : ''"
                            class="flex-1 button button-text"
                            data-cy="skip-personalization"
                            @click="handleSkip"
                        >
                            Skip personalization <i class="bi bi-arrow-right" />
                        </button>
                    </Transition>
                </div>
            </div>
            <div
                :class="[columnOrderInverted ? 'order-2' : 'order-3', answerTimeout || step === STEP.INSIGHTS ? 'hidden md:flex' : 'flex']"
                class="md:order-2 z-20 col-span-5 md:flex flex-col gap-5 md:gap-10 md:py-8 px-6 justify-center items-center gradient-background rounded-[30px]"
            >
                <div ref="marbleRef">
                    <CoachingModeMarble :is-loading="true" size="big" />
                </div>
                <div class="flex flex-col gap-2 mx-auto max-w-md text-center">
                    <div class="px-2 max-h-96 overflow-y-auto text-xl md:text-2xl font-semibold text-[#262626] tracking-tighter leading-snug" v-text="rightHeadline"></div>
                    <div class="px-2 text-base font-medium text-[#868686] leading-normal" v-text="rightBody"></div>
                </div>
                <Transition name="fade">
                    <ContextMeter v-if="isRecording" class="hidden md:block" :running="contextMeterRunning" />
                </Transition>
                <Transition name="fade" mode="in-out">
                    <div
                        v-if="showTimeEstimate"
                        class="hidden md:inline-block mx-auto rounded-lg font-semibold text-base text-[#525252] bg-white p-2 leading-tight border border-2 border-[#E5BCEF]"
                    >
                        3 min
                    </div>
                </Transition>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { router } from "@inertiajs/vue3";
import Bubbles from "~vue/components/Bubbles.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import ContextMeter from "~vue/Onboarding/ContextMeter.vue";
import Insights from "~vue/Onboarding/Insights.vue";
import Outro from "~vue/Onboarding/Outro.vue";
import Personalization from "~vue/Onboarding/Personalization.vue";
import PrivacyDialog from "~vue/Onboarding/PrivacyDialog.vue";
import Questions from "~vue/Onboarding/Questions.vue";
import SavedMemory, { TOTAL_ANIMATION_MS } from "~vue/Onboarding/SavedMemory.vue";
import ScheduleCheckin from "~vue/Onboarding/ScheduleCheckin.vue";
import { logError, logUserInteraction } from "~vue/utils/logUtils";
import throttle from "lodash.throttle";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";

const STEP = {
    PERSONALIZATION: "personalization",
    QUESTIONS: "questions",
    INSIGHTS: "insights",
    OUTRO: "outro",
    CHECKIN: "checkin",
};

const ANSWER_TIMEOUT_MS = 12000;
const FADE_DELAY_MS = 500;
const SAVED_MEMORY_ANIMATION_DELAY_MS = FADE_DELAY_MS;
const LAST_SAVED_MEMORY_ANIMATION_BUFFER_MS = TOTAL_ANIMATION_MS + SAVED_MEMORY_ANIMATION_DELAY_MS;

const { $setEventContext } = inject("globalProperties");
const props = defineProps({
    current_date: String,
    organization_logo_url: String,
    errors: Object,
    language_options: Array,
    default_language: String,
    insights: Array,
    step: String,
    event_context: Object,
    user_details: Object,
    post_onboarding_redirect: String,
    post_onboarding_action_text: String,
    insight_checkin: {
        type: { title: String, content: String },
        default: () => ({ title: "", content: "" }),
    },
});

const activeListeningError = ref(null);
const answerTimeout = ref(null);
const columnOrderInverted = ref(false);
const isLoading = ref(false);
const isRecording = ref(false);
const isTranscribing = ref(false);
const recordingLevelSamplingRate = ref(200);
const questionsComponentRef = useTemplateRef("questionsComponentRef");
const marbleRef = useTemplateRef("marbleRef");

const contextMeterRunning = ref(false);
const DEFAULT_HEADLINE = "I'm Nadia, your safe space at work";
const DEFAULT_BODY = "To make sure I'm the best thinking partner for you, I'd love to get to know you better. ";
const rightHeadline = ref(DEFAULT_HEADLINE);
const rightBody = ref(DEFAULT_BODY);

const CHECKIN_HEADLINE = "When works best for you?";
const CHECKIN_BODY = "I’ll check in with you weekly so you'll always have someone in your corner to process, plan, and problem-solve";
watch(
    () => props.step,
    (value) => {
        if (value === STEP.CHECKIN) {
            rightHeadline.value = CHECKIN_HEADLINE;
            rightBody.value = CHECKIN_BODY;
        }
    },
);
const questions = ref([
    {
        text: "Tell me more about your day to day? What are you currently working on, and what does your team look like?",
        answer: null,
        llmResponse: null,
        offTopic: false,
        showMemoryAnimation: false,
    },
    {
        text: "Has anyone given you feedback recently that has stuck with you? Or are there specific areas you want to grow in that we can focus on first?",
        answer: null,
        llmResponse: null,
        offTopic: false,
        showMemoryAnimation: false,
    },
]);

const currentQuestionIndex = ref(0);
const currentQuestion = computed(() => questions.value[currentQuestionIndex.value]);
const showSkip = computed(() => props.step === STEP.QUESTIONS);
const showPrivacyDialog = computed(() => {
    // PrivacyDialog is more integrated in the personalization and outro step, so it's added there.
    return [STEP.QUESTIONS, STEP.INSIGHTS, STEP.CHECKIN].includes(props.step);
});

const isRecordingProcessing = computed(() => isRecording.value || isTranscribing.value);
const logoUrl = computed(() => props.organization_logo_url || "/static/logo.png");
const showTimeEstimate = computed(
    () => [STEP.PERSONALIZATION, STEP.QUESTIONS].includes(props.step) && !isLoading.value && !isRecordingProcessing.value && questions.value.every((q) => q.answer === null),
);

function marbleCenterCoordinates() {
    return getElementCenterXY(marbleRef.value);
}

function questionsStackCenterCoordinates() {
    return getElementCenterXY(questionsComponentRef.value.cardStackRef);
}

onMounted(() => {
    $setEventContext(props.event_context);
});

onUnmounted(() => {
    if (answerTimeout.value) {
        clearTimeout(answerTimeout.value);
    }
    $setEventContext({});
});

function getElementCenterXY(element) {
    if (!element) {
        return { x: 0, y: 0 };
    }

    const rect = element.getBoundingClientRect();
    const x = rect.x + rect.width / 2;
    const y = rect.y + rect.height / 2;
    return { x, y };
}

function handleNextQuestion() {
    logUserInteraction("onboarding_next_clicked", {});
    if (currentQuestionIndex.value === questions.value.length - 1) {
        isLoading.value = true;
        questions.value[currentQuestionIndex.value].showMemoryAnimation = true;
        /*
         * Allow the saved memory animation to run completely
         */
        setTimeout(() => {
            postResponse(
                {
                    intent: "insights",
                    form: questions.value.map((q) => ({ question: q.text, answer: q.answer })),
                },
                {
                    only: ["step", "errors", "insights"],
                    onFinish: () => {
                        rightHeadline.value = "Based on what you’ve shared, I have some thoughts to guide our journey together";
                        rightBody.value = "Let's go through them together";
                        resetColumnOrder();
                    },
                },
            );
        }, LAST_SAVED_MEMORY_ANIMATION_BUFFER_MS);
    } else {
        rightHeadline.value = "I'll remember that";
        rightBody.value = "Over time, I will learn about you so you'll get more specific, personalized support in every chat";
        resetColumnOrder();
        nextTick(() => {
            questions.value[currentQuestionIndex.value].showMemoryAnimation = true;
            currentQuestionIndex.value++;
        });
    }
}

const handleRecordingLevel = computed(() =>
    throttle(({ isBelowSilenceThreshold }) => {
        /*
         * Check whether user is silent. Context meter should only run
         * when we detect signal.
         * If silence is detected, then the sampling rate is
         * increased so that signal is detected sooner. If signal is detected,
         * the sampling rate is decreased to accomodate natural pausing.
         */
        contextMeterRunning.value = !isBelowSilenceThreshold;
        if (isBelowSilenceThreshold) {
            recordingLevelSamplingRate.value = 200;
        } else {
            recordingLevelSamplingRate.value = 4000;
        }
    }, recordingLevelSamplingRate.value),
);

function handleRecordingStart() {
    isRecording.value = true;
}

function handleRecordingStop() {
    isRecording.value = false;
    contextMeterRunning.value = false;
}

async function handleQuestionAnswer({ answer, append, onFinish, usingMic }) {
    let nextAnswer;
    isLoading.value = true;
    activeListeningError.value = null;

    const currentAnswer = questions.value[currentQuestionIndex.value].answer;
    if (append && currentAnswer !== null) {
        nextAnswer = `${currentAnswer}\n\n${answer}`;
    } else {
        logUserInteraction(`onboarding_q${currentQuestionIndex.value + 1}_completed`, { mic: usingMic });
        nextAnswer = answer;
    }

    try {
        const response = await fetch("/coach/active_listening", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
                form: {
                    question: questions.value[currentQuestionIndex.value].text,
                    answer: nextAnswer,
                },
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to obtain active_listening response");
        }

        const payload = await response.json();

        /*
         * The mobile design calls for breaking up the answer screen in two sections.
         * First, the user's answer is shown for some seconds (per ANSWER_TIMEOUT_MS).
         * Then, the LLM answer is rendered.
         *
         * To avoid complicating the logic just for mobile, there is no explicit state within
         * the Questions.vue component to manage this. Transitioning between the breakpoint
         * should not affect any logic that's running in this step. You should be able to resize
         * the window and see both breakpoint views without any trouble. That is to say, desktop
         * should look like desktop, and mobile should look like mobile.
         *
         * When we get a response from the LLM, a timeout is set and it is passed as a boolean
         * flag to the Questions.vue component. This component uses media queries to apply styling
         * specific to each breakpoint based on this flag and its internal state. In other words,
         * v-if is not used at all to manage this mobile view, just CSS via the `hidden` utility class.
         *
         * Users can specifically skip ahead to see the LLM answer. Questions.vue emits an event when this
         * happens, and the timeout is cleared.
         *
         * The mobile view also calls for inverting the order of the main layout's rows when
         * the LLM answer is shown. To this end, when the answer timeout clears, we set a boolean
         * that is then used to apply CSS that switches the row order. Again, this is only for mobile.
         * You should be able to resize to desktop and not see the inverted rows.
         *
         * The regular row order is restored when the user continues to the next question or to the
         * next step.
         */
        answerTimeout.value = setTimeout(handleAnswerTimeout, ANSWER_TIMEOUT_MS);

        if (payload.status === "guardrails_violated") {
            rightHeadline.value = "Sorry, let’s try again";
            rightBody.value = "Let's get back on track and discuss topics about your role, your position, and your team at work.";
            questions.value[currentQuestionIndex.value].offTopic = true;
            return;
        }

        questions.value[currentQuestionIndex.value].answer = nextAnswer;
        questions.value[currentQuestionIndex.value].llmResponse = payload.content;
        questions.value[currentQuestionIndex.value].offTopic = false;

        rightHeadline.value = payload.content;
        rightBody.value = "Just to make sure - did I hear this right? You can always share more context.";
    } catch (e) {
        activeListeningError.value = e;
        logError(e);
        handleErrorFallback();
    } finally {
        isLoading.value = false;
        onFinish();
    }
}

function handleErrorFallback() {
    rightHeadline.value = "Sorry, I am having trouble understanding.";
    rightBody.value = "Let's try submitting your answer again.";
}

function resetColumnOrder() {
    columnOrderInverted.value = false;
}

function clearAnswerTimeout() {
    if (answerTimeout.value) {
        clearTimeout(answerTimeout.value);
        answerTimeout.value = null;
    }
}

function handleViewAnswer() {
    clearAnswerTimeout();
    columnOrderInverted.value = true;
}

function handleMoreContext() {
    clearAnswerTimeout();
    resetColumnOrder();
}

function handleAnswerTimeout() {
    answerTimeout.value = null;
    columnOrderInverted.value = true;
}

function postResponse(payload, { only = [], onFinish = () => {} } = {}) {
    isLoading.value = true;

    router.post("", payload, {
        only,
        headers: {
            "X-CSRFToken": getCookie("csrftoken"),
        },
        onFinish: () => {
            isLoading.value = false;
            onFinish();
        },
    });
}

function handleDone({ onFinish }) {
    postResponse({ intent: "done" }, { only: ["step", "user_details", "post_onboarding_redirect", "post_onboarding_action_text"], onFinish });
}

function handleCheckin({ insight_checkin, onFinish }) {
    postResponse({ intent: "checkin", insight_checkin }, { only: ["step", "user_details", "insight_checkin", "post_onboarding_redirect"], onFinish });
}

function handleSkip() {
    postResponse({ intent: "skip" });
}

function onPersonalizationSubmit(formValues) {
    postResponse({ intent: "personalization", form: formValues }, { only: ["step", "errors", "user_details"] });
}
</script>

<style type="postcss">
.error-list li {
    @apply text-xs tracking-tighter font-medium text-red-700 text-left;
}

.button {
    @apply flex justify-center items-center gap-1 transition-colors ease-in duration-150 rounded-[20px] px-9 py-3 leading-none font-semibold tracking-tighter text-base w-full ring-2 ring-inset;
}

.button-text {
    @apply md:px-6 px-0 py-3 transition-colors ease-in duration-150 font-semibold text-base leading-none ring-0;
}

.button-text:not(.button-text--muted) {
    @apply text-[#555BA2] hover:text-[#4B508F];
}

.button-text--muted {
    @apply hover:text-[#7C7C7C] text-[#8C8C8C];
}

.button-primary {
    @apply bg-[#555BA2] hover:bg-[#4B508F] hover:ring-[#4B508F] text-white ring-[#555BA2];
}

.button-danger {
    @apply text-white ring-[#CF1322] bg-[#CF1322];
}

.button-primary:disabled,
.button-danger:disabled {
    @apply bg-[#F5F5F5] ring-[#F5F5F5] text-[#8C8C8C];
}

.button-text:disabled {
    @apply opacity-40;
}

@media (min-width: 768px) {
    .gradient-background {
        background: linear-gradient(360deg, #e9defa 0%, #fff6eb 100%);
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity v-bind(FADE_DELAY_MS + "ms") ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
