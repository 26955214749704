<template>
    <div class="stacked-grid">
        <div class="stacked-grid-item">
            <div class="leading-none text-center tracking-tighter font-semibold text-[#555BA2]">{{ currentInsightIndex + 1 }} of {{ insights.length }}</div>
            <div class="tracking-tighter text-left w-full px-6 md:px-10 py-10 flex flex-col gap-2 rounded-lg my-[24px] insight-content">
                <div class="flex flex-col items-center gap-2">
                    <svg width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            id="Vector"
                            d="M10.823 10.4228L15.4366 11.3909L3.22512 22.6549L7.12009 14.6473L7.68084 13.4945L6.4262 13.2312L1.8378 12.2684L13.421 2.24364L10.1293 9.00642L9.56806 10.1594L10.823 10.4228ZM2.23744 23.5659C2.23758 23.5658 2.23772 23.5657 2.23785 23.5655L2.23759 23.5658L2.23744 23.5659Z"
                            fill="url(#paint0_linear_5120_241036)"
                            stroke="url(#paint1_linear_5120_241036)"
                            stroke-width="2" />
                        <defs>
                            <linearGradient id="paint0_linear_5120_241036" x1="-3.36482" y1="-9.68562" x2="29.3216" y2="5.86528" gradientUnits="userSpaceOnUse">
                                <stop offset="0.06" stop-color="#FF2891" />
                                <stop offset="1" stop-color="#003FDC" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_5120_241036" x1="-3.36482" y1="-9.68562" x2="29.3216" y2="5.86528" gradientUnits="userSpaceOnUse">
                                <stop offset="0.06" stop-color="#FF2891" />
                                <stop offset="1" stop-color="#003FDC" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div class="tracking-tight text-md font-semibold insight-subtitle">{{ insights[currentInsightIndex].title }}</div>
                </div>
                <div class="font-semibold text-2xl text-[#262626] mt-4">{{ insights[currentInsightIndex].subtitle }}</div>
                <div class="md:overflow-y-auto md:max-h-64 text-base font-semibold text-black/50">{{ insights[currentInsightIndex].content }}</div>
            </div>
            <div class="flex flex-col items-center gap-3">
                <div class="flex flex-col items-center gap-3">
                    <BaseButton v-if="currentInsightIndex % 2 === 0" theme="startChat" @click="diveDeeper">
                        <CoachingModeMarble class="w-6" />
                        Dive deeper
                    </BaseButton>
                    <div v-else class="flex justify-center">
                        <button type="button" class="button button-primary commit-to-this" @click="commitToThis"><i class="bi bi-calendar mr-3" />Commit to this</button>
                    </div>
                    <div v-if="seenLast && currentInsightIndex === 2" class="hidden md:inline-block mx-auto">
                        <button :disabled="submitting" type="button" class="button button-text" @click="handleDone">Go to Home</button>
                    </div>
                </div>
                <div class="hidden md:flex items-center justify-center gap-6 mt-6">
                    <button type="button" title="Previous" :disabled="isFirst || submitting" class="arrow-button" @click="handlePrevious">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13" fill="none">
                            <g id="arrow">
                                <path
                                    id="arrow_2"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18.5205 6.85547C18.5205 6.51029 18.2407 6.23047 17.8955 6.23047L3.15439 6.23047L7.08745 2.29741C7.33153 2.05333 7.33153 1.6576 7.08745 1.41353C6.84337 1.16945 6.44764 1.16945 6.20357 1.41353L1.20357 6.41353C0.959488 6.6576 0.959488 7.05333 1.20357 7.29741L6.20357 12.2974C6.44764 12.5415 6.84337 12.5415 7.08745 12.2974C7.33153 12.0533 7.33153 11.6576 7.08745 11.4135L3.15439 7.48047L17.8955 7.48047C18.2407 7.48047 18.5205 7.20065 18.5205 6.85547Z"
                                    fill="#555BA2"
                                    stroke="#555BA2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </g>
                        </svg>
                    </button>
                    <button type="button" title="Next" :disabled="isLast || submitting" class="arrow-button" @click="handleNext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13" viewBox="0 0 19 13" fill="none">
                            <g id="arrow">
                                <path
                                    id="arrow_2"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.729492 6.85547C0.729492 6.51029 1.00931 6.23047 1.35449 6.23047L16.0956 6.23047L12.1625 2.29741C11.9185 2.05333 11.9185 1.6576 12.1625 1.41353C12.4066 1.16945 12.8024 1.16945 13.0464 1.41353L18.0464 6.41353C18.2905 6.6576 18.2905 7.05333 18.0464 7.29741L13.0464 12.2974C12.8024 12.5415 12.4066 12.5415 12.1625 12.2974C11.9185 12.0533 11.9185 11.6576 12.1625 11.4135L16.0956 7.48047L1.35449 7.48047C1.00931 7.48047 0.729492 7.20065 0.729492 6.85547Z"
                                    fill="#555BA2"
                                    stroke="#555BA2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </g>
                        </svg>
                    </button>
                </div>
                <div class="md:hidden">
                    <button
                        :disabled="submitting"
                        type="button"
                        class="button button-primary"
                        @click="isLast ? handleDone() : handleNext()"
                        v-text="isLast ? 'Go to Home' : 'Next insight'"></button>
                </div>
            </div>
        </div>
        <div class="rotate-[4.6deg] translate-x-[8px] stacked-grid-item"></div>
        <div class="rotate-[2.6deg] translate-x-[8px] stacked-grid-item"></div>
    </div>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import BaseButton from "~vue/components/BaseButton.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { openUrl } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
    insights: Array,
});

const emit = defineEmits(["done", "checkin"]);

const submitting = ref(false);
const currentInsightIndex = ref(0);
const seenLast = ref(false);
const isLast = computed(() => currentInsightIndex.value === props.insights.length - 1);
const isFirst = computed(() => currentInsightIndex.value === 0);

watch(currentInsightIndex, (val) => {
    if (!seenLast.value && val === props.insights.length - 1) {
        seenLast.value = true;
    }
    logUserInteraction(`onboarding_insight${currentInsightIndex.value + 1}_viewed`, {});
});

onMounted(() => {
    logUserInteraction(`onboarding_insight${currentInsightIndex.value + 1}_viewed`, {});
});

function handleNext() {
    currentInsightIndex.value++;
}

function handlePrevious() {
    currentInsightIndex.value--;
}

function handleDone() {
    submitting.value = true;
    emit("done", { onFinish: () => (submitting.value = false) });
    logUserInteraction("done_click", { action: "done", type: "onboarding_insight" });
}

/** Creates a SuggestedTopic, whose id is passed as a URL search param to start a chat with the insight fed as context for a conversation starter. */
function diveDeeper() {
    fetch("/api/chat/create_suggested_topic", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
            title: props.insights[currentInsightIndex.value].title,
            is_suggested_topic: false,
            prompt_messages: [
                {
                    role: "assistant",
                    hidden: true,
                    content: props.insights[currentInsightIndex.value].content,
                },
                {
                    role: "user",
                    hidden: true,
                    content: "Dive deeper into this insight. Do not acknowledge this message.",
                },
            ],
        }),
    })
        .then((response) => response.json())
        .then((data) => {
            emit("done", { onFinish: () => (submitting.value = false) });
            openUrl(`/coach/start/?topic_id=${data.topic_id}`);
        })
        .catch((error) => console.error(error));
    logUserInteraction("dive_deeper_click", {
        action: "dive_deeper",
        type: "onboarding_insight",
        insight_title: insights[currentInsightIndex].title,
        insight_index: currentInsightIndex.value + 1,
    });
}

/** Schedule a check-in for 5 days later */
function commitToThis() {
    submitting.value = true;
    emit("checkin", { insight_checkin: props.insights[currentInsightIndex.value], onFinish: () => (submitting.value = false) });
    logUserInteraction("commit_to_this_click", {
        action: "commit_to_this",
        type: "onboarding_insight",
        insight_title: insights[currentInsightIndex].title,
        insight_index: currentInsightIndex.value + 1,
    });
}
</script>

<style scoped>
.stacked-grid {
    @apply grid place-items-center max-w-[640px] mx-auto justify-stretch w-full;
    grid-template-areas: "stacked";
}

.stacked-grid-item {
    @apply transition-colors md:border md:border-2 rounded-2xl flex-col gap-6 transition-all duration-300 ease-in-out z-20 md:bg-white w-full h-full border-[#F5F5F5] md:px-[72px] md:py-[52px];
    grid-area: stacked;
    transform-origin: 0 0;
}

.stacked-grid-item:first-child {
    @apply z-30;
}

.stacked-grid-item:not(:first-child) {
    @apply hidden md:flex;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.02);
}

.arrow-button {
    @apply bg-[#555BA244] hover:bg-[#4B508F44] disabled:opacity-50;
    color: #555ba2;
    border-radius: 100%;
    width: 34px;
    height: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.commit-to-this {
    @apply px-6 py-4 rounded-full;
}

/*
 * Getting a gradient rounded border with a semi-transparent gradient background is not trivial.
 * This solution was taken from https://stackoverflow.com/a/66936639 and seems to be compatible
 * across browsers.
 */
.insight-content {
    position: relative;
    border: 2px solid transparent;
    background: linear-gradient(125deg, rgba(255, 40, 145, 0.04) -22.98%, rgba(0, 63, 220, 0.04) 113.56%);
    background-clip: padding-box;
}

.insight-content::before {
    position: absolute;
    padding: 2px;
    inset: 0;
    background: linear-gradient(125deg, #ff2891 -22.98%, #003fdc 113.56%);
    content: "";
    border-radius: 8px;
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.insight-subtitle {
    background: linear-gradient(125deg, #ff2891 -22.98%, #003fdc 113.56%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Note that full width here sets the width of the gradient used to paint the text. */
    width: 100%;
    text-align: center;
}
</style>
